@charset "utf-8";
/**
 * Created by henian.xu on 2017/11/8.
 *
 */

.badge {
  display: inline-block;
  background-color: $gray5;
  padding: 0 0.5em;
  line-height: $line-height;
  border-radius: $body-font-size * $line-height/2;
  color: $color-text;

  $size: (
    small: 0.75,
    big: 1.1,
    more: 1.2,
    super: 1.5,
  );

  @each $key, $value in $size {
    &.#{$key + ''} {
      font-size: $value * 100%;
      padding: 0 ($value * 0.5em);
    }
  }
}
@each $key, $value in $colors {
  .badge-#{'' + $key} {
    $color: $color-text;
    @if (lightness_($value) <71) {
      $color: #fff;
    }
    background-color: $value;
    color: $color;
    &.plane {
      border-radius: 0.05rem;
      background-color: transparent;
      color: $value;
      border: 1px solid $value;
    }
  }
}
