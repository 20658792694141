@charset "utf-8";
/**
 * Created by henian.xu on 2018/4/18.
 *
 */

.price {
  color: $color-price;
  font-weight: bold;
  &:before {
    content: '￥';
    font-size: 66.666%;
  }
  > .old {
    font-weight: normal;
    color: $gray6;
    font-size: 66.666%;
    text-decoration: line-through;
    margin-left: 0.2em;
  }
}
.price-rmb {
  color: $color-price;
  &:before {
    content: '￥';
    font-size: 66.666%;
  }
  > .old {
    font-weight: normal;
    color: $gray6;
    font-size: 66.666%;
    text-decoration: line-through;
    margin-left: 0.2em;
  }
}

.page-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: $gray5;
  padding: 0 $padding;
  text-align: center;
  margin: $margin-big 0;

  > span {
    padding: 0 $padding;
  }
  &:before,
  &:after {
    display: block;
    width: 1%;
    flex-grow: 1;
    content: '';
    height: 1px;
    background-color: $gray5;
  }
}

.address-box {
  padding-bottom: 5px;
  background: #fff url('~@/assets/images/comm/address-border.png') center bottom
    repeat-x;
  background-size: auto 5px;
}

.goods-nothing-icon {
  text-align: center;
  margin-top: 40%;
  > .f-icon {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: 0.6rem;
    border-radius: 50%;
    background-color: $gray5;
    color: #fff;
    margin-bottom: $margin;
  }
}

.goods-nothing {
  flex-grow: 1;
  padding: $padding-big;
  text-align: center;
}
