@import "@/assets/style/_index.scss";


























































.withdraw-item {
  background-color: #fff;
  margin-bottom: $margin;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    > .label {
      font-weight: bold;
    }
  }
  > .body,
  > .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    border-top: 1px solid $color-border;
  }
  > .body {
    justify-content: flex-start;
    > .account-name {
      margin-left: $margin;
    }
  }
}
